<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <!-- Filter -->
    <form method="post" data-vv-scope="frmFilter" novalidate="novalidate">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-12">
          <div class="card card-fullheight">
            <div class="card-body">
              <h5 class="box-title mb-3">{{ $t('sidebar.tools') }}</h5>
              <div class="row">
                <custom-input
                  v-model="formData.internal_code"
                  name="formData.internal_code"
                  :label="this.$i18n.t('form.tools.internal_code')"
                  type="text"
                  rootClassName="col-sm-4 form-group mb-4"
                  inputClassName="md-form-control"
                  :max-length="25"
                  v-validate="{ required: false }"
                  :error="submitted = errors.first('formData.internal_code')">
                </custom-input>

                <!-- Agente -->
                <div class="col-sm-6 form-group mb-4">
                  <label>{{ this.$i18n.t('filters.filAgenteOrigem') }}</label>
                  <v-select  class="md-form-control" name="formData.agent_origin" label="nome" :clearable="false" :searchable="true" v-model="formData.agent_origin" @search="agentOriginFetchOptions" :options="agentOriginList"
                             v-validate="{ required: true }" >
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ (option.cpf_cnpj) ? '(' + option.cpf_cnpj +')' : '' }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ (option.cpf_cnpj) ? '(' + option.cpf_cnpj +')' : '' }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('formData.agent_origin')" class="royalc-error-field">{{ errors.first('formData.agent_origin') }}</small>
                  </transition>
                </div>

                <!-- Período -->
                <div class="row">
                  <div class="py-1 col-md-6">
                    <label>{{ $t('kpi.volume.period') }}</label>
                    <div class="md-form md-form-control mb-0 mt-0">
                      <div class="input-group-icon input-group-icon-right mb-0">
                        <div class="md-form md-form-date mb-0 mt-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                          <flat-picker name="formData.beginDateInvoice" class="md-form-control" v-model="formData.beginDateInvoice" :config="datePickerConfig"  v-validate="{ required: true }" />
                          <label :class="{'active': formData.beginDateInvoice}">{{ $t('filter.Start') }}</label>
                        </div>
                      </div>
                    </div>
                    <transition name="slide" mode="">
                      <small v-if="errors.first('formData.beginDateInvoice')" class="royalc-error-field">{{ errors.first('formData.beginDateInvoice') }}</small>
                    </transition>
                  </div>
                  <!-- Data Final -->
                  <div class="py-1 col-md-6">
                    <label class="hidden-label">{{ $t('kpi.volume.period') }}</label>
                    <div class="md-form md-form-control mb-0 mt-0">
                      <div class="input-group-icon input-group-icon-right mb-0">
                        <div class="md-form md-form-date mb-0 mt-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                          <flat-picker name="formData.endDateInvoice" class="md-form-control" v-model="formData.endDateInvoice" :config="datePickerConfig" v-validate="{ required: true }"  />
                          <label :class="{'active': formData.endDateInvoice}">{{ $t('filter.End') }}</label>
                        </div>
                      </div>
                    </div>
                    <transition name="slide" mode="">
                      <small v-if="errors.first('formData.endDateInvoice')" class="royalc-error-field">{{ errors.first('formData.endDateInvoice') }}</small>
                    </transition>
                  </div>
                </div>
              </div>

              <!-- Submit -->
              <div class="col-12">
                <button type="button" class="btn btn btn-outline-light pull-right" @click="onFilter">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- Result -->
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="row">
                <div class="col-8 px-0">
                  <span  class="currency">BRL / Real</span>
                  <div class="mt-4">
                    <small  class="title d-block pb-1">Total</small>
                    <span class="currency-value d-block">R$ {{ $util.formatNumber(total.valor_total, 2, ',' , '.', '0,00')}}</span>
                  </div>
                </div>
                <div class="col-4 px-0">
                  <div class="mb-4">
                    <small  class="title d-block pb-1">Processos</small>
                    <span class="currency-value-paid d-block">{{ $util.formatNumber(total.processos, 0)}}</span>
                  </div>
                </div>
              </div>
              </div>
              <div class="col-6">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-12">
        <div class="card card-fullheight">
          <div class="card-body">
            <button v-if="invoices.length > 0" type="button" class="btn btn btn-outline-light pull-right" @click="onToCsv">
              Exportar para CSV
            </button>
            <!-- Detalhes -->
            <h6>Detalhes</h6>
            <table class="table align-middle table-clickable fixed">
              <thead>
                <tr>
                  <th>Data da Fechamento<i
                    class="sort-icon ti-angle-double-up" style="opacity:0.65;position:relative;float:right"></i></th>
                  <th>Processo <i
                    class="sort-icon ti-angle-double-down"
                    style="opacity:0.30000000000000004;position:relative;float:right"></i></th>
                  <th>Cliente <i class="sort-icon grey sort icon"
                                                                                     style="opacity:1;position:relative;float:right"></i>
                  </th>
                  <th>Agente Origem <i class="sort-icon ti-angle-double-up" style="opacity:1;position:relative;float:right"></i>
                  </th>
                  <th>Pessoa da Fatura
                    <i class="sort-icon grey sort icon" style="opacity:1;position:relative;float:right"></i></th>
                  <th>Tipo de Movimentação</th>
                  <th>Valor Total</th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="(invoice, index) in invoices" v-bind:key="index">
                <td>{{ $util.formatDate(invoice.dataFechamentoProcesso )}}</td>
                <td>{{ invoice.numeroProcesso }}</td>
                <td> {{ invoice.cliente }}</td>
                <td style="width: 250px;">{{ invoice.agenteOrigem }}</td>
                <td>{{ invoice.pessoaPadrao.nome }}</td>
                <td>{{ invoice.tipoMovimentacaoPadrao.name}}
                </td>
                <td>{{ $util.formatNumber(invoice.valorTotal)}}</td>
              </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Date Picker
import FlatPicker from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import 'flatpickr/dist/flatpickr.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import PersonService from '@/services/PersonService'
import NfseService from '@/services/NfseService'

export default {
  name: 'ToolsNfseInternacional',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tools') + ' - %s'
    }
  },
  data () {
    return {
      invoices: [],
      total: [],
      formData: {
        internal_code: null,
        beginDateInvoice: null,
        endDateInvoice: null,
        agent_origin: null
      },
      formErrors: {},
      isLoading: true,
      fullPage: true,
      agentOriginList: [],
      submitted: false,
      datePickerConfig: {
        altInputClass: 'form-date md-form-control-date',
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d 00:00:00',
        locale: Portuguese // locale for this instance only
      }
    }
  },
  components: {
    Loading,
    vSelect,
    CustomInput,
    FlatPicker
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
  },
  created () {
    let _this = this

    // Load basilares data
    _this.isLoading = false
  },
  computed: {

  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_tier: object.id } }).href
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    agentOriginFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        PersonService.getCargoAgent(filters).then(res => {
          _this.agentOriginList = res.data.data
          resolve(_this.agentOriginList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    onFilter () {
      var _this = this
      this.submitted = true
      _this.$validator.validateAll('frmFilter').then((valid) => {
        if (valid) {
          _this.isLoading = true

          let filterData = JSON.parse(JSON.stringify(this.formData))

          // Method accept an array
          filterData.agent_origin = []
          filterData.agent_origin.push(this.formData.agent_origin.id)

          // Add Sort By
          filterData.sort = 'agenteOrigem|asc,dataBaixa|asc,numeroProcesso|desc'

          NfseService.toolsNfseInternacional(filterData).then(response => {
            this.invoices = response.data.data
            this.total = response.data.total
          }).finally(() => {
            _this.isLoading = false
          })
        }
      })
    },
    onForceSync (invoice) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let filterData = {}
          filterData.internal_code = invoice.numeroProcesso

          NfseService.toolsNfseInternacionalForceSync(filterData).then(response => {
            if (response.data.executed) {
              _this.$alertSwal.toast('Solicitação de sincronização do processo realizada! <br/> O processo será atualizado nos próximos minutos', 'success')
            } else {
              _this.$alertSwal.toast('Não foi possível forçar a atualizaçãodo processo. Se o probelma persistir por favor informe o adminstrador do sistema!', 'error')
            }
          }).finally(() => {
            _this.isLoading = false
          })
        }
      })
    },
    onForceSyncBulk () {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let filterData = JSON.parse(JSON.stringify(this.formData))

          // Method accept an array
          filterData.agent_origin = []
          filterData.agent_origin.push(this.formData.agent_origin.id)

          NfseService.toolsNfseInternacionalForceSyncBulk(filterData).then(response => {
            if (response.data.executed) {
              _this.$alertSwal.toast('Solicitação de sincronização realizada! <br/> O processo  poderá demorar um pouco e será atualizado nos próximos minutos', 'success')
            } else {
              _this.$alertSwal.toast('Não foi possível forçar a atualizaçãodo processo. Se o probelma persistir por favor informe o adminstrador do sistema!', 'error')
            }
          }).finally(() => {
            _this.isLoading = false
          })
        }
      })
    },
    async onToCsv (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          let filterData = JSON.parse(JSON.stringify(this.formData))

          // Method accept an array
          filterData.agent_origin = []
          filterData.agent_origin.push(this.formData.agent_origin.id)

          // Add Sort By
          filterData.sort = 'agenteOrigem|asc,dataBaixa|asc,numeroProcesso|desc'
          let fileName = 'NF Internacional - Check.csv'
          NfseService.toolsNfseInternacionalCsv(filterData).then(res => {
            const url = global.window.URL.createObjectURL(new Blob([res.data]))
            const link = global.document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            global.document.body.appendChild(link)
            link.click()
            _this.isLoading = false
          }).finally(() => {
            _this.isLoading = false
          })
        }
      })
    }

  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;
  }

  .hidden-label {
    color: transparent;
  }

  .currency {
    color: #84754E;
    font-weight: 600;
  }
  .title {
    min-height: 0;
    font-size: 10px;
    color: #A5A4A4;
  }
  .currency-value {
    font-size: 25px;
    font-weight: 600;
    color: #383838;
  }
  .currency-value-paid, .currency-value-overdue {
    font-size: 13px;
    font-weight: 600;
  }
  .currency-value-paid {
    color: #71CC74;
  }
  .currency-value-overdue {
    color: #F3614E;
  }
</style>
